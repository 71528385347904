import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header className="bg-gray-900 text-white p-2 shadow-md">
      <div className="container mx-auto flex items-center justify-between">
        <h1 className="text-2xl font-bold">
          <Link to="/" 
            className="text-2xl font-bold text-white no-underline hover:no-underline hover:text-gray-400 transition duration-300 ease-in-out"
            >
          <img
            src="/logo.png"
            alt=""
            className="h-24 w-24 mr-6"
          />
          </Link>
        </h1>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-4 mr-6">
          <Link to="/" className="hover:text-gray-400 hover:no-underline">Accueil</Link>
          <Link to="/services-arts" className="hover:text-gray-400 hover:no-underline">Artistes</Link>
          <Link to="/services-mariages" className="hover:text-gray-400 hover:no-underline">Futurs mariés</Link>
          <Link to="/services-entreprises" className="hover:text-gray-400 hover:no-underline">Entreprises</Link>
          <Link to="/contact" className="hover:text-gray-400 hover:no-underline">Contact</Link>
        </nav>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden flex items-center px-3 py-2 border rounded text-white border-white"
          onClick={toggleMenu}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
          </svg>
        </button>
      </div>

      {/* Mobile Navigation Menu */}
      <div className={`md:hidden ${isOpen ? 'block' : 'hidden'} bg-gray-800 p-4 mt-2`}>
        <Link to="/" className="block text-white py-2 hover:bg-gray-700">Accueil</Link>
        <Link to="/services-arts" className="block text-white py-2 hover:bg-gray-700">Artistes</Link>
        <Link to="/services-mariages" className="block text-white py-2 hover:bg-gray-700">Futurs mariés</Link>
        <Link to="/services-entreprises" className="block text-white py-2 hover:bg-gray-700">Entreprises</Link>
        <Link to="/contact" className="block text-white py-2 hover:bg-gray-700">Contact</Link>
      </div>
    </header>
  );
};

export default Header;
