import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const GalerieMariagePage: React.FC = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialise AOS avec une durée d'animation
  }, []);

  return (
    <div className="bg-gray-800 text-white">
      {/* Section d'Introduction */}
      <section
        className="relative flex items-center justify-center h-96 bg-cover bg-center"
        style={{
          backgroundImage: "url('../images/galeries/events/4.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-6 flex flex-col items-center justify-center text-center z-10">
          <p className="text-5xl md:text-6xl font-bold mb-4" data-aos="fade-up">
            Galerie - Événementiel          
          </p>
        </div>
      </section>
      
     
   {/* Section Galerie */}
<section className="py-16 bg-gray-900">
  <div className="container mx-auto px-6 text-center">
    <h2 className="text-4xl font-bold mb-12 text-white" data-aos="fade-up">
      Galerie
    </h2>
    <p
      className="text-lg mb-6 text-gray-300"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      Explorez quelques-unes de nos photos pour voir notre travail en action.
    </p>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      {Array.from({ length: 12 }, (_, index) => (
        <div
          className="relative aspect-square"
          key={index}
        >
          <img
            src={`../images/galeries/events/${index + 1}.png`}
            alt={`Aperçu de la vidéo ${index + 1}`}
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <svg
              className="w-16 h-16 text-white group-hover:scale-110 transition-transform"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12l7-7 7 7-7 7-7-7z"
              ></path>
            </svg>
          </div>
        </div>
      ))}
    </div>
     
  </div>
</section>

   {/* Section Galerie */}
<section className="py-16 bg-gray-900">
  <div className="container mx-auto px-6 text-center">
  <p
      className="text-lg mb-6 text-gray-300"
      data-aos="fade-up"
      data-aos-delay="100"
    >
      Explorez une de nos vidéo teaser pour voir notre travail en action.
    </p>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
    <a
              href="https://youtu.be/fAQrnrLs1-c?si=Km4p-WSqK5PiTLI7" 
              target="_blank"
              rel="noopener noreferrer"
              className="w-full  group"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="relative">
                <img
                  src="/images/wedding.png"
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"  
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7-7 7 7-7 7-7-7z"></path>
                  </svg>
                </div>
              </div>
            </a>
    </div>
     
  </div>
</section>



    </div>
  );
};

export default GalerieMariagePage;