import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const HomePage: React.FC = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialise AOS avec une durée d'animation
  }, []);

  return (
    <div className="bg-gray-800 text-white">
      {/* Section d'Introduction */}
      <section
        className="relative flex items-center justify-center h-screen bg-cover bg-center"
        style={{
          backgroundImage: "url('images/pyxis.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-6 flex flex-col items-center justify-center text-center z-10">
          <h1 className="text-5xl md:text-6xl font-bold mb-4" data-aos="fade-up">
            Capturons l'Essence de Votre Vision
          </h1>
          <p className="text-lg md:text-xl mb-6" data-aos="fade-up" data-aos-delay="200">
            Chez WIDE PIXEL, nous transformons vos idées en visuels époustouflants et mémorables.
          </p>
          <a
            href="/contact"
            className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Contactez-nous
          </a>
        </div>
      </section>
      
      {/* Section des Services avec Redirection */}
      {/* <section className="py-16 bg-gray-900">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-12" data-aos="fade-up">
            Nos services
          </h2>
          <p className="text-lg mb-6" data-aos="fade-up" data-aos-delay="100">
            Découvrez comment nous pouvons transformer votre vision en réalité.
          </p>
          <div>
            <a
                href="/services-mariages"
                className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
                data-aos="fade-up"
                data-aos-delay="200"
                >
                Pour les futurs mariés
              </a>
            </div><br/>
          <div>
            <a
                href="/services-arts"
                className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
                data-aos="fade-up"
                data-aos-delay="200"
                >
                Pour les artistes
              </a>
            </div><br/>
            <div>
              <a
                href="/services-entreprises"
                className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
                data-aos="fade-up"
                data-aos-delay="200"
                >
                Pour les entreprises
              </a>
            </div>
        </div>
      </section> */}
      
      {/* Section des Valeurs */}
      <section className="py-16 bg-gray-900">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-12 text-white" data-aos="fade-up">Nos services</h2>
          <div className="flex flex-wrap justify-center gap-8">
          
            <div className="w-full md:w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="300">
              <a
                  href="/services-mariages"
                  className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  >
                  Pour les futurs mariés
                </a>
            </div>
            <div className="w-full md:w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="300">
              <a
                  href="/services-arts"
                  className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  >
                  Pour les artistes
                </a>
            </div>
            <div className="w-full md:w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="300">
              <a
                  href="/services-entreprises"
                  className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  >
                  Pour les entreprises
                </a>
            </div>
          </div>
        </div>
      </section>
      
      
      {/* Section des Valeurs */}
      <section className="py-16 bg-gray-900">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-12 text-white" data-aos="fade-up">Nos valeurs</h2>
          <div className="flex flex-wrap justify-center gap-8">
            <div className="w-full md:w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="100">
            <h3 className="text-2xl font-bold mb-4">Créativité</h3>
            <p className="text-lg">Nous valorisons l'originalité et l'innovation, en cherchant constamment de nouvelles façons de donner vie à vos idées.</p>
            </div>
            <div className="w-full md:w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="200">
              <h3 className="text-2xl font-bold mb-4">Qualité</h3>
              <p className="text-lg">La qualité est notre priorité. Nous nous engageons à fournir des résultats exceptionnels qui surpassent les attentes de nos clients.</p>
            </div>
            <div className="w-full md:w-1/3 bg-gray-800 p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="300">
              <h3 className="text-2xl font-bold mb-4">Collaboration</h3>
              <p className="text-lg">Nous croyons en la puissance de la collaboration et travaillons étroitement avec nos clients pour transformer leurs idées en réalité.</p>
            </div>
          </div>
        </div>
      </section>

     {/* Section des Vidéos YouTube */}
     <section className="py-16 bg-gray-900">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-4xl font-bold mb-12 text-white" data-aos="fade-up">
            Galerie
          </h2>
          <p className="text-lg mb-6 text-gray-300" data-aos="fade-up" data-aos-delay="100">
            Explorez quelques-unes de nos vidéos pour voir notre travail en action.
          </p>
          <div className="flex flex-wrap justify-center gap-8">
            <a
              href="https://youtu.be/fyiRw6o1SOw?si=eSLIsY3n5XBJ3PXp"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-1/2 lg:w-1/3 group"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="relative">
                <img
                  src="/images/yanys.png"
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"  

                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7-7 7 7-7 7-7-7z"></path>
                  </svg>
                </div>
              </div>
            </a>
            <a
              href="https://youtu.be/rLsVhrgPt54"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-1/2 lg:w-1/3 group"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="relative">
                <img
                  src="/images/resto.png"
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"  
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7-7 7 7-7 7-7-7z"></path>
                  </svg>
                </div>
              </div>
            </a>
            <a
              href="https://youtu.be/fAQrnrLs1-c?si=Km4p-WSqK5PiTLI7" 
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-1/2 lg:w-1/3 group"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="relative">
                <img
                  src="/images/wedding.png"
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"  
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7-7 7 7-7 7-7-7z"></path>
                  </svg>
                </div>
              </div>
            </a>
            <a
              href="https://www.youtube.com/watch?v=8C6lgsri7zc&ab_channel=DATAPERPANE" 
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-1/2 lg:w-1/3 group"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="relative">
                <img
                  src="/images/imdukal.jpeg"
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"  
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7-7 7 7-7 7-7-7z"></path>
                  </svg>
                </div>
              </div>
            </a>


            <a
              href="https://youtu.be/iqA2Cs-1bWA?si=9FZTtszfB1m0kjte" 
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-1/2 lg:w-1/3 group"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="relative">
                <img
                  src="/images/reportage.jpeg"
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"  
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7-7 7 7-7 7-7-7z"></path>
                  </svg>
                </div>
              </div>
            </a>
            <a
              href="https://youtu.be/-fUfZKkoP0E" 
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-1/2 lg:w-1/3 group"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="relative">
                <img
                  src="/images/venise.png"
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"  
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg className="w-16 h-16 text-white group-hover:scale-110 transition-transform" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12l7-7 7 7-7 7-7-7z"></path>
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;