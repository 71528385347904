import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';

const GalerieEntreprisesPage: React.FC = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialise AOS avec une durée d'animation
  }, []);

  const videos = [
    {
      href: "https://youtu.be/rLsVhrgPt54",
      src: "/images/resto.png",
    },
    {
      href: "https://youtu.be/iqA2Cs-1bWA?si=9FZTtszfB1m0kjte",
      src: "/images/reportage.jpeg",
    },
  ];

  return (
    <div className="bg-gray-800 text-white">
      {/* Section d'Introduction */}
      <section
        className="relative flex items-center justify-center h-96 bg-cover bg-center"
        style={{
          backgroundImage: "url('../images/galeries/entreprises/1.jpg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-6 flex flex-col items-center justify-center text-center z-10">
          <p className="text-5xl md:text-6xl font-bold mb-4" data-aos="fade-up">
            Entreprises          
          </p>
        </div>
      </section>
      
     
   {/* Section Galerie */}
   <section className="py-16 bg-gray-900">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold mb-12 text-white" data-aos="fade-up">
          Galerie
        </h2>
        <p className="text-lg mb-6 text-gray-300" data-aos="fade-up" data-aos-delay="100">
          Explorez quelques-unes de nos réalisations pour voir notre travail en action.
        </p>
        <div className="flex flex-wrap justify-center gap-8">
          {videos.map((video, index) => (
            <a
              key={index}
              href={video.href}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full md:w-1/2 lg:w-1/3 group"
              data-aos="fade-up"
              data-aos-delay={100 + index * 100}
            >
              <div className="relative">
                <img
                  src={video.src}
                  alt="Aperçu de la vidéo"
                  className="w-full h-64 object-cover rounded-lg shadow-lg"
                />
                <div className="absolute inset-0 flex items-center justify-center">
                  <svg
                    className="w-16 h-16 text-white group-hover:scale-110 transition-transform"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 12l7-7 7 7-7 7-7-7z"
                    ></path>
                  </svg>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>



    </div>
  );
};

export default GalerieEntreprisesPage;