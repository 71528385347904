  export interface Service {
    title: string;
    description: string;
    aosDelay: number;
    pricePerDay: number;
    postProductionPricePerDay: number;
    supOperatorPrice?: number;
  }
  
  export interface ServiceCategory {
    title: string;
    services: Service[];
    icon?: string;
  }

  export interface ServiceTitle {
    value: string;
    label: string;
  }

  export type SelectedService = {
    service: Service;
    productionDays: number;
    postProductionOption: 'simple' | 'multiple';
    additionalOperators: number; // Nombre d'opérateurs supplémentaires pour ce service
  };
  
  export const isVideoCategory = (categoryTitle: string): boolean => {
    return categoryTitle.toLowerCase().includes('vidéo');
  };
  
  // export const extractServiceTitles = (categories: ServiceCategory[]): ServiceTitle[] => {
  //   const titles: ServiceTitle[] = [];
  
  //   categories.forEach(category => {
  //     const categoryPrefix = isVideoCategory(category.title) ? 'Vidéo' : '';
  
  //     category.services.forEach(service => {
  //       titles.push({
  //         value: `${categoryPrefix} ${service.title}`,
  //         label: `${categoryPrefix} ${service.title}`,
  //       });
  //     });
  //   });
  
  //   return titles;
  // };
  
 // servicesData.ts

export const serviceEntreprisesCategories: ServiceCategory[] = [
  {
    title: 'Production vidéo',
    services: [
      {
        title: 'Présentation de l\'entreprise',
        description: 'Vidéo expliquant votre histoire, valeurs et mission.',
        aosDelay: 100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Interview des dirigeants',
        description: 'Interviews des dirigeants pour présenter leur vision et stratégie.',
        aosDelay: 300,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Démonstration de produits',
        description: 'Vidéos montrant les fonctionnalités et avantages des produits.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Présentation de services',
        description: 'Vidéos explicatives des services proposés.',
        aosDelay: 500,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Tutoriels d\'utilisation',
        description: 'Tutoriels pour aider à utiliser vos produits ou services.',
        aosDelay: 600,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Couverture de séminaires',
        description: 'Vidéos capturant l\'essence de vos séminaires.',
        aosDelay: 700,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Lancement de produit',
        description: 'Vidéos documentant le lancement de vos produits.',
        aosDelay: 800,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Activités de team building',
        description: 'Vidéos montrant la cohésion et l\'engagement des équipes.',
        aosDelay: 900,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Spot publicitaire',
        description: 'Vidéos publicitaires pour promouvoir vos produits ou services.',
        aosDelay: 1000,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Annonce spéciale',
        description: 'Vidéos pour des promotions ou événements importants.',
        aosDelay: 1100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Campagne marketing',
        description: 'Vidéos pour booster vos campagnes marketing.',
        aosDelay: 1200,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
    ],
  },
  {
    title: 'Services en photographie',
    services: [
      {
        title: 'Photos de portraits professionnels',
        description: 'Portraits professionnels de haute qualité pour les employés.',
        aosDelay: 100,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de produits',
        description: 'Photos détaillées et attrayantes de vos produits.',
        aosDelay: 200,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos événementielles',
        description: 'Photos professionnelles pour les séminaires et conférences.',
        aosDelay: 300,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de l\'environnement de travail',
        description: 'Photos de vos bureaux et espaces de travail.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
      {
        title: 'Photos de communication',
        description: 'Photos percutantes pour brochures et supports marketing.',
        aosDelay: 500,
        pricePerDay: 600,
        postProductionPricePerDay: 600,
        supOperatorPrice: 350,
      },
    ],
  },
];

export const serviceArtsCategories: ServiceCategory[] = [
  {
    title: 'Clips Musicaux',
    services: [
      {
        title: 'Réalisation de clips musicaux',
        description: 'Création de clips sur mesure reflétant votre univers musical, de l’écriture au montage final.',
        aosDelay: 100,
        pricePerDay: 700,
        postProductionPricePerDay: 600,
        supOperatorPrice: 400,
      },
      {
        title: 'Teasers musicaux',
        description: 'Courtes vidéos percutantes pour promouvoir vos singles ou albums.',
        aosDelay: 200,
        pricePerDay: 600,
        postProductionPricePerDay: 500,
        supOperatorPrice: 350,
      },
      {
        title: 'Sessions live studio',
        description: 'Captation et production de performances en studio pour un rendu acoustique authentique.',
        aosDelay: 300,
        pricePerDay: 800,
        postProductionPricePerDay: 600,
        supOperatorPrice: 400,
      },
      {
        title: 'Lyric videos',
        description: 'Vidéos illustrant les paroles de vos chansons pour engager votre public.',
        aosDelay: 400,
        pricePerDay: 500,
        postProductionPricePerDay: 400,
        supOperatorPrice: 300,
      },
      {
        title: 'Performance créative',
        description: 'Mise en scène et captation unique pour sublimer vos talents musicaux.',
        aosDelay: 500,
        pricePerDay: 900,
        postProductionPricePerDay: 700,
        supOperatorPrice: 450,
      },
    ],
  },
  {
    title: 'Événements Musicaux et Spectacles',
    services: [
      {
        title: 'Captation de concerts',
        description: 'Enregistrement multi-caméras de vos performances live avec un rendu professionnel.',
        aosDelay: 100,
        pricePerDay: 1000,
        postProductionPricePerDay: 800,
        supOperatorPrice: 500,
      },
      {
        title: 'Aftermovies de festivals',
        description: 'Résumé dynamique et immersif pour immortaliser vos événements musicaux.',
        aosDelay: 200,
        pricePerDay: 800,
        postProductionPricePerDay: 600,
        supOperatorPrice: 400,
      },
      {
        title: 'Streaming live',
        description: 'Diffusion en direct de vos concerts ou showcases avec une qualité irréprochable.',
        aosDelay: 300,
        pricePerDay: 1200,
        postProductionPricePerDay: 0,
        supOperatorPrice: 500,
      },
      {
        title: 'Vidéos promotionnelles',
        description: 'Spots vidéos pour annoncer vos prochains événements musicaux ou spectacles.',
        aosDelay: 400,
        pricePerDay: 600,
        postProductionPricePerDay: 500,
        supOperatorPrice: 350,
      },
      {
        title: 'Documentaires musicaux',
        description: 'Récits visuels sur l’histoire et les coulisses de vos projets artistiques.',
        aosDelay: 500,
        pricePerDay: 900,
        postProductionPricePerDay: 800,
        supOperatorPrice: 450,
      },
    ],
  },
];

export const serviceMariagesCategories: ServiceCategory[] = [{
  title: "Nos Services pour les Mariages en Photo et Vidéo",
  icon: "💍",
  services: [
      {
          title: "Reportage Photo de Mariage",
          description: "Des clichés intemporels pour immortaliser les moments clés de votre grand jour, du début à la fin.",
          aosDelay: 100,
          pricePerDay: 1200,
          postProductionPricePerDay: 500
      },
      {
          title: "Vidéo de Mariage",
          description: "Un film cinématographique pour revivre les émotions et les moments inoubliables de votre journée.",
          aosDelay: 200,
          pricePerDay: 1500,
          postProductionPricePerDay: 600
      },
      {
          title: "Séance Engagement (Pré-Mariage)",
          description: "Immortalisez vos moments avant le mariage avec une séance photo ou vidéo créative.",
          aosDelay: 300,
          pricePerDay: 800,
          postProductionPricePerDay: 400
      },
      {
          title: "Clip Teaser de Mariage",
          description: "Un court-métrage dynamique pour teaser le film complet de votre mariage.",
          aosDelay: 400,
          pricePerDay: 600,
          postProductionPricePerDay: 250
      },
      {
          title: "Couverture Live de Mariage",
          description: "Diffusion en direct de votre cérémonie ou réception pour que vos proches éloignés puissent vivre le moment.",
          aosDelay: 500,
          pricePerDay: 1000,
          postProductionPricePerDay: 300
      },
      {
          title: "Album Photo de Mariage",
          description: "Création d'un album photo personnalisé pour conserver un souvenir unique et intemporel.",
          aosDelay: 600,
          pricePerDay: 700,
          postProductionPricePerDay: 250
      },
      {
          title: "Montage Vidéo et Highlight",
          description: "Un montage vidéo sur mesure avec les moments forts de votre mariage.",
          aosDelay: 700,
          pricePerDay: 800,
          postProductionPricePerDay: 300
      },
      {
          title: "Consultation et Planification",
          description: "Conseils et assistance pour planifier les prises de vue, les lieux, et les moments à capturer.",
          aosDelay: 800,
          pricePerDay: 500,
          postProductionPricePerDay: 0
      }
  ]
}];