import React from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { serviceArtsCategories } from '../services/servicesData'; 

const ServicesArtsPage = () => {
  React.useEffect(() => {
    AOS.init({
      duration: 800, // Durée de l'animation
    });
  }, []);

  return (
    <>
    <section
        className="relative flex items-center justify-center h-96 bg-cover bg-center"
        style={{
          backgroundImage: "url('../images/galeries/arts/1.jpg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative container mx-auto px-6 flex flex-col items-center justify-center text-center z-10">
          <p className="text-5xl md:text-6xl font-bold mb-4" data-aos="fade-up">
            Artistes          
          </p>
        </div>
      </section>
    <div>  
        <section className="py-16 bg-gray-800 text-white">
        <div className="relative container mx-auto px-6 flex flex-col items-center justify-center text-center z-10">
          <h1 className="text-5xl md:text-6xl font-bold mb-4" data-aos="fade-up">
            Nous réalisons des clips sur mesure pour les artistes.
          </h1>
          <a
            href="/galerie/arts"
            className="bg-gray-800 hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg transition duration-300 ease-in-out"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Voir quelques-unes de nos réalisations
          </a>
        </div>
        </section>
    </div>
    <div>
      {serviceArtsCategories.map((category, categoryIndex) => (
        <section key={categoryIndex} className="py-16 bg-gray-800 text-white">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-12" data-aos="fade-up">
              {category.title}
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {category.services.map((service, serviceIndex) => (
                <div
                  key={serviceIndex}
                  className="bg-gray-700 p-6 rounded-lg shadow-lg"
                  data-aos="fade-right"
                  data-aos-delay={service.aosDelay}
                >
                  <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
                  <p className="text-s">{service.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      ))}
    </div>
    </>
    
  );
};

export default ServicesArtsPage;